<template>
  <BodySimple>
    <template #header>
      <h2 class="text-lg font-bold">
        <img src="@/assets/images/users.png" class="h-20 inline-block" />
        کاربران
      </h2>
    </template>
    <template #default>
      <div class="bg-blueGray-100 border border-blueGray-200 p-3 rounded-md">
        <form @submit.prevent="submit()">
          <div class="grid sm:grid-cols-4 gap-2">
            <MnrText v-model="search" placeholder="جستجو" />
            <MnrSelect
              v-model="branch.value"
              :options="branch.options"
              placeholder="دسته"
            />
            <MnrSelect
              v-model="searchBy.value"
              :options="searchBy.options"
              placeholder="بر اساس"
            />
            <div>
              <Button type="submit" class="text-lg">جستجو</Button>
            </div>
          </div>
        </form>
      </div>
      <div class="grid md:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-3 mt-5">
        <div
          v-for="user in users"
          :key="user.id"
          class="bg-blueGray-100 border border-blueGray-200 rounded-md overflow-hidden"
        >
          <div class="flex p-2 bg-blueGray-200">
            <div class="w-1/2 font-bold text-lg">{{ user.first_name }}</div>
            <div class="w-1/2 font-bold text-lg text-left">{{ user.last_name }}</div>
          </div>
          <div class="flex p-2">
            <div class="w-1/2">id</div>
            <div class="w-1/2 font-bold text-left">{{ user.uid }}</div>
          </div>
          <div class="flex p-2">
            <div class="w-1/2">نام کاربری</div>
            <div class="w-1/2 font-bold text-left" dir="ltr">@{{ user.username }}</div>
          </div>
          <div class="w-full mx-auto p-2">
            <Button
              :to="
                user.panel && {
                  name: 'Panel',
                  params: { id: user.panel?.id },
                }
              "
              :disabled="!user.panel"
              class="h-11"
              color="cyan"
            >
              پنل
            </Button>
          </div>
        </div>
      </div>
    </template>
  </BodySimple>
</template>

<script>
import BodySimple from "@/layouts/BodySimple.vue";
import MnrText from "@/components/mnr/MnrText.vue";
import MnrSelect from "@/components/mnr/MnrSelect.vue";
import Button from "@/components/Button.vue";
export default {
  components: { BodySimple, MnrText, MnrSelect, Button },
  data() {
    return {
      users: [],
      search: "",
      branch: {
        value: "panel",
        options: [
          {
            text: "همه",
            value: "all",
          },
          {
            text: "پنل",
            value: "panel",
          },
        ],
      },
      searchBy: {
        value: "all",
        options: [
          {
            text: "همه",
            value: "all",
          },
          {
            text: "id",
            value: "id",
          },
          {
            text: "یوزرنیم",
            value: "username",
          },
          {
            text: "نام",
            value: "name",
          },
        ],
      },
    };
  },
  mounted() {
    let $this = this;

    this.$router
      .isReady()
      .then(function () {
        $this.search = $this.$route.query.search ?? $this.search;
        $this.branch.value = $this.$route.query.branch ?? $this.branch.value;
        $this.searchBy.value = $this.$route.query.searchBy ?? $this.searchBy.value;

        $this.submit();
      })
      .catch();
  },
  methods: {
    submit() {
      this.users = [];
      this.$router.replace({
        query: {
          search: this.search,
          branch: this.branch.value,
          searchBy: this.searchBy.value,
        },
      });

      let $this = this;
      // console.log($this.$store.state.user.id);
      $this.$axios
        .get("/api/admin/users", {
          params: {
            id: $this.$store.state.user.id,
            search: $this.search,
            branch: $this.branch.value,
            searchBy: $this.searchBy.value,
          },
        })
        .then(function (response) {
          // console.log(response);
          $this.users = response.data.users;
        })
        .catch(function (error) {
          // console.log(error);
        })
        .finally(function () {
          // always executed
          $this.firstLoading = false;
        });
    },
  },
};
</script>
