<template>
  <div class="flex flex-col h-full overflow-y-hidden">
    <div class="flex-grow overflow-y-auto scroll-style pb-32 lg:pb-0">
      <slot />
    </div>
    <div class="flex-shrink-0 fixed w-full bottom-0 lg:relative">
      <div class="bg-blueGray-100 border-t border-blueGray-300 p-1">
        <slot name="bottom" />
      </div>
    </div>
  </div>
</template>
