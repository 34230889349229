<template>
  <component :is="$slots.bottom ? 'BodyWithBottom' : 'Empty'">
    <div
      v-if="$slots.header"
      class="p-5 md:p-10 lg:p-15 bg-blueGray-100 border-b border-blueGray-200"
      id="mainHeader"
    >
      <slot name="header" />
    </div>
    <div class="p-3 md:p-10 lg:p-15 relative min-h-screen sm:min-h-0">
      <div class="body-loading" v-if="$store.state.firstCreate == false || loading">
        <div class="p-3 rounded-md bg-blueGray-50 inline-block text-coolGray-800">
          در حال بارگذاری لطفا صبر کنید ...
        </div>
      </div>
      <slot v-if="$store.state.firstCreate && loading == false" />
      <div class="my-5 block md:hidden">
        <slot name="bottom" />
      </div>
    </div>

    <template #bottom v-if="$store.state.firstCreate && loading == false">
      <slot name="bottom" />
    </template>
  </component>
</template>

<script>
import Empty from "@/layouts/Empty.vue";
import BodyWithBottom from "@/layouts/BodyWithBottom.vue";
export default {
  components: {
    Empty,
    BodyWithBottom,
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    loading: {
      handler(newVal) {
        if (newVal == false && this.$route.hash) {
          setTimeout(() => {
            var container = document.getElementById("mainContainer");
            var header = document.getElementById("mainHeader");
            var scrollTo = document.getElementById(this.$route.hash.slice(1));
            if (scrollTo) {
              container.scrollTop = scrollTo.offsetTop + header.offsetHeight;
            }
          }, 200);
        }
      },
      immediate: true,
    },
  },
};
</script>

<style>
.body-loading {
  position: absolute;
  background-color: rgba(255, 255, 255, 0.8);
  width: 100%;
  height: 100%;
  z-index: 10;
  padding: 20px;
  font-size: 1.5em;
}
</style>
